<template>
  <v-container>
    <v-row>
      <v-col cols="1" style="margin-left: 10px; padding-bottom: 23px">
        <BackArrow class="mt-2" :route="'conectarComSuaEmpresa'" />
      </v-col>
      <v-col cols="10" class="mt-1" style="margin-left: 10px">
        <p class="main-title">Telemedicina</p>
      </v-col>
    </v-row>

    <v-form
      id="formcadastro"
      ref="formcadastro"
      @submit.prevent="salvar"
      :lazy-validation="true"
    >
      <v-container class="d-flex flex-column align-space-between" fluid style="height: 80vh">
        <v-col cols="12" class="text-left">
          <p class="">O cancelamento foi solicitado com sucesso.</p>
        </v-col>
        <generic-btn
          class="mb-4 mt-2"
          color="#E9EBFD"
          :defaultTheme="false"
          :btn-props="{ rounded: true, elevation: 8, block: true }"
          style="font-weight: bold;"
          :on-click="() => confirmCancel()"
        >
          SAIR
        </generic-btn>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import GenericBtn from "../../../components/generic-btn";
import BackArrow from "../../../components/covid/back-arrow/index.vue";

export default {
  components: {
    GenericBtn,
    BackArrow,
  },
  data() {
    return {};
  },
  methods: {
    confirmCancel() {
      this.$router.push({ name: 'conectarComSuaEmpresa' });
    },
  },
};
</script>
